import { configureStore } from '@reduxjs/toolkit'
import clientsReducer from './features/clientsSlice'
import userReducer from './features/userSlice';


export const store = configureStore({
  reducer: {
    clients: clientsReducer,
    user: userReducer
  },
})
