import { AddTask, Cancel } from '@mui/icons-material';
import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';

const DeleteClientModal = ({ clientName, onCloseFn, onSubmitFn }) => {
  let isOpen = !!clientName;
  return (
    <Modal open={isOpen} onClose={onCloseFn}>
      <Box sx={{ ...styles.modal }}>
        <Typography variant="h6" pb="25px" >
          Do you want to permanently delete <code style={styles.code}>{clientName}</code>?
        </Typography>
        <Box display="flex" justifyContent="space-evenly">
          <Button variation="primary" color="error" onClick={onSubmitFn}>
            <AddTask />
            &nbsp; Delete
          </Button>
          <Button variation="menu" onClick={onCloseFn}>
            <Cancel />
            &nbsp; Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  code: {
    color: 'crimson',
    backgroundColor: '#f1f1f1',
    padding: '3px'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
    maxWidth: '95%'
  },
  container: {
    margin: '0 auto',
    padding: '50px 100px'
  }
};

export default DeleteClientModal;
