import jwt_decode from 'jwt-decode';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAuthToken } from '../services/cognito';

export const fetchCurrentUser = createAsyncThunk('usersApi/fetchCurrentUserStatus', async (code) => {
  const response = await getAuthToken(code);
  return response;
});

const initialState = {
  idToken: null,
  email: null,
  expires: null,
  loading: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true;
    },
    logout: (state) => {
      state.idToken = null;
      state.loading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.idToken = action.payload.id_token;
      state.email = jwt_decode(action.payload.id_token).email;
      state.loading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
