import { AddCircle } from '@mui/icons-material';
import { Box, Button, Alert, Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Loader } from '../components/Loader';
import { ClientTable } from '../components/ClientTable';
import { ClientCreateModal } from '../components/ClientCreateModal';
import {
  fetchClientsThunk,
  clearCredentials,
  clearError
} from '../features/clientsSlice';

const Clients = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const idToken = useSelector((state) => state.user.idToken);
  const clients = useSelector((state) => state.clients.value);
  const loading = useSelector((state) => state.clients.loading);
  const error = useSelector((state) => state.clients.error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (idToken) {
      dispatch(fetchClientsThunk(idToken));
    }
  }, [dispatch, idToken]);

  if (loading) {
    return <Loader />;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(clearError());
  };

  const onCloseFn = () => {
    if (idToken) {
      dispatch(clearCredentials());
    }
    setIsOpen(false);
  };

  if (idToken) {
    return (
      <>
        <Box>
          <Button
            sx={{ marginBottom: '25px' }}
            variant="outlined"
            onClick={() => setIsOpen(true)}
          >
            <AddCircle />
            &nbsp; New
          </Button>
          <ClientCreateModal
            title="Create New Client:"
            isOpen={isOpen}
            onCloseFn={onCloseFn}
          />
          <ClientTable clients={clients} />
        </Box>

        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert severity="error" onClose={handleSnackbarClose}>
            {error}
          </Alert>
        </Snackbar>
      </>
    );
  }
};

export default Clients;
