import { AppBar, Toolbar, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { LoginButton } from '../components/LoginButton';
import { LogoutButton } from '../components/LogoutButton';
import { login, logout, fetchCurrentUser } from '../features/userSlice';
import { signIn, signOut } from '../services/cognito';

const getAuthCode = () => {
  const searchParams = new URL(document.location).searchParams;
  const code = searchParams.get('code');
  return code;
};

export const Header = () => {
  const { email, idToken, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  if (!idToken) {
    const code = getAuthCode();
    if (code) {
      dispatch(fetchCurrentUser(code));
      signOut();
    } else {
      if (!loading && !email) {
        dispatch(login());
        signIn();
      }
    }
  }

  return (
    <AppBar position="static">
      <Toolbar>
        {idToken ? email : <LoginButton title="Login" signIn={signIn} />}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
        {idToken && <LogoutButton title="Logout" signOut={() => dispatch(logout())} />}
      </Toolbar>
    </AppBar>
  );
};
