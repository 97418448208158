import { AddTask, Cancel, ContentCopy } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import momentTZ from 'moment-timezone';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { createClientThunk } from '../features/clientsSlice';

import { PLATFORMS_LIST } from '../constants/platforms';

const initialData = {
  client_name: '',
  site_id: '',
  time_zone: 'US/Central',
  platform: '',
  callsRead: true,
  callsWrite: false,
  require_pings: true,
};

const timeZonesList = momentTZ.tz
  .names()
  .filter((item) => item.startsWith('US/'));

export const ClientCreateModal = ({ title, isOpen, onCloseFn }) => {
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.user.idToken);
  const newClientId = useSelector((state) => state.clients.newClientId);
  const newClientSecret = useSelector((state) => state.clients.newClientSecret);
  const [modalData, setModalData] = useState(initialData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback(
    (event) => {
      setModalData({ ...modalData, [event.target.name]: event.target.value });
    },
    [modalData]
  );

  const handleCheck = useCallback(
    (event) => {
      setModalData({ ...modalData, [event.target.name]: event.target.checked });
    },
    [modalData]
  );

  const onSubmitFn = () => {
    setIsSubmitting(true);
    dispatch(createClientThunk({ idToken, data: modalData }));
  };

  const isSubmitAvailable = useMemo(() => {
    return (
      modalData.client_name &&
      modalData.site_id &&
      modalData.platform &&
      modalData.time_zone &&
      !isSubmitting
    );
  }, [modalData, isSubmitting]);

  return (
    <Modal open={isOpen} onClose={onCloseFn}>
      {newClientId ? (
        <Box sx={{ ...styles.modal, minWidth: 650 }}>
          <Typography variant="caption">
            Success! Please save these credentials, you will never see them
            again...
          </Typography>
          <Box display="flex" justifyContent="space-evenly">
            <TextField
              id="client-id-field"
              disabled
              value={newClientId}
              label="Client ID"
              variant="filled"
              color="success"
              fullWidth
              margin="normal"
            />
            <CopyToClipboard text={newClientId}>
              <Tooltip title={`Copy to clipboard`}>
                <IconButton>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Box>
          <Box display="flex" justifyContent="space-evenly">
            <TextField
              id="client-secret-field"
              disabled
              value={newClientSecret}
              label="Client Secret"
              variant="filled"
              color="success"
              fullWidth
              margin="normal"
            />
            <CopyToClipboard text={newClientSecret}>
              <Tooltip title={`Copy to clipboard`}>
                <IconButton>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Box>
          <Typography variant="overline" display="block">
            click outside this popup to close
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.modal}>
          <Typography variant="h5">{title}</Typography>
          <Box component="form" noValidate autoComplete="off">
            <Box display="flex" gap={1} marginY={2}>
              <TextField
                id="client-name-field"
                name="client_name"
                onChange={handleChange}
                value={modalData.client_name}
                label="Client Name"
                variant="standard"
                fullWidth
              />
              <TextField
                id="site-id-field"
                name="site_id"
                onChange={handleChange}
                value={modalData.site_id}
                label="Site ID"
                variant="standard"
                fullWidth
              />
            </Box>
            <Box display="flex" gap={1}>
              <FormControl fullWidth>
                <InputLabel id="timezone-select-label">Site Timezone</InputLabel>
                <Select
                  labelId="timezone-select-label"
                  name="time_zone"
                  id="site-timezone-select"
                  onChange={handleChange}
                  value={modalData.time_zone}
                  label="Site Timezone"
                >
                  {timeZonesList.map((item, index) => (
                    <MenuItem key={`timezone-${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="platform-select-label">Platform</InputLabel>
                <Select
                  labelId="platform-select-label"
                  name="platform"
                  id="site-platform-select"
                  onChange={handleChange}
                  value={modalData.platform}
                  label="Platform"
                >
                  {PLATFORMS_LIST.map((item, index) => (
                    <MenuItem key={`platform-${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={modalData.callsRead}
                    onChange={handleCheck}
                    name="callsRead"
                  />
                }
                label="calls/read"
                disabled
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={modalData.callsWrite}
                    onChange={handleCheck}
                    name="callsWrite"
                  />
                }
                label="calls/write"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={modalData.require_pings}
                    onChange={handleCheck}
                    name="require_pings"
                  />
                }
                label="Require Pings"
              />
            </FormGroup>
          </Box>

          <Box display="flex" justifyContent="space-evenly">
              <Button
                variation="primary"
                onClick={onSubmitFn}
                disabled={!isSubmitAvailable}
              >
                <AddTask />
                &nbsp; Create
              </Button>
              <Button variation="menu" onClick={onCloseFn}>
                <Cancel />
                &nbsp; Cancel
              </Button>
            </Box>
        </Box>
      )}
    </Modal>
  );
};

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
    maxWidth: '95%',
    minWidth: 400
  }
};
