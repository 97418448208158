import { Logout } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

export const LogoutButton = ({ title, signOut }) => {
  return (
        <Tooltip title={title}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => signOut()}
            sx={{ mr: 2 }}
          >
            <Logout />
          </IconButton>
        </Tooltip>
  );
};
