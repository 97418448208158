import React, { useState, useMemo } from 'react';
import { DeleteForever } from '@mui/icons-material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { deleteClientThunk } from '../features/clientsSlice';
import DeleteClientModal from './DeleteClientModal';

import { DEFAULT_PLATFORM } from '../constants/platforms';

const tableCells = [
  {
    id: 'clientName',
    label: 'Client Name',
    getValue: (row) => row.clientName,
    props: {
      component: 'th',
      scope: 'row'
    }
  },
  {
    id: 'platform',
    label: 'Platform',
    getValue: (row) => row.platform || DEFAULT_PLATFORM
  },
  {
    id: 'clientId',
    label: 'Client ID',
    getValue: (row) => row.clientId
  },
  {
    id: 'siteId',
    label: 'SONIFI Site ID',
    getValue: (row) => row.siteId
  },
  {
    id: 'access',
    label: 'Access Level',
    getValue: (row) => row.access.join(', ')
  },
  {
    id: 'requirePings',
    label: 'Require Pings?',
    getValue: (row) => row.requirePings ? 'Yes' : 'No'
  }
];

function descendingComparator(a, b, orderBy) {
  const comparisonField = tableCells.find(field => field.id === orderBy);
  if (comparisonField.getValue(b) < comparisonField.getValue(a)) {
    return -1;
  }
  if (comparisonField.getValue(b) > comparisonField.getValue(a)) {
    return 1;
  }
  return 0;
}

const ASCENDING = 'asc';
const DESCENDING = 'desc';

function getComparator(order, orderBy) {
  return order === DESCENDING
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const ClientTable = ({ clients = [] }) => {
  const initialData = {};
  const [deleteClient, setDeleteClient] = useState(initialData);

  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.user.idToken);
  const deleteFn = (clientId) => {
    setDeleteClient(initialData);
    dispatch(deleteClientThunk({ idToken, clientId }));
  };

  const [order, setOrder] = useState(ASCENDING);
  const [orderBy, setOrderBy] = useState(tableCells[0].id);

  const sortedClients = useMemo(
    () => clients.slice().sort(getComparator(order, orderBy)),
    [clients, order, orderBy]
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === ASCENDING;
    setOrder(isAsc ? DESCENDING : ASCENDING);
    setOrderBy(property);
  };

  if (clients.length < 1) {
    return <div></div>;
  }

  return (
    <TableContainer component={Paper}>
      <DeleteClientModal
        clientName={deleteClient.clientName}
        onSubmitFn={() => deleteFn(deleteClient.clientId)}
        onCloseFn={() => setDeleteClient(initialData)}
      />
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {tableCells.map((tableCell) => (
              <TableCell key={tableCell.id}>
                <TableSortLabel
                  active={orderBy === tableCell.id}
                  direction={orderBy === tableCell.id ? order : ASCENDING}
                  onClick={() => handleRequestSort(tableCell.id)}
                >
                  {tableCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedClients.map((row, rowIndex) => (
            <TableRow
              key={row.clientId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {tableCells.map((tableCell) => (
                <TableCell
                  key={`${row.clientId}-${tableCell.id}`}
                  {...tableCell.props}
                >
                  {tableCell.getValue(row)}
                </TableCell>
              ))}
              <TableCell align="right">
                <Button
                  variant="outlined"
                  color="error"
                  onClickCapture={() => setDeleteClient(row)}
                >
                  <DeleteForever />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
