import { Login } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
// import { useState } from 'react'

export const LoginButton = ({ title, signIn }) => {
  return (
        <Tooltip title={title}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => signIn()}
            sx={{ mr: 2 }}
          >
            <Login />
          </IconButton>
        </Tooltip>
  );
};

// const styles = {
//   container: {
//     margin: '0 auto',
//     padding: '50px 100px'
//     }
// }
