import { COGNITO_CLIENT_ID, COGNITO_DOMAIN_PREFIX, COGNITO_REDIRECT_URI, AWS_REGION } from '../constants/environment';

export const signIn = () => {
  window.location = `https://${COGNITO_DOMAIN_PREFIX}.auth.${AWS_REGION}.amazoncognito.com/login?response_type=code&client_id=${COGNITO_CLIENT_ID}&redirect_uri=${COGNITO_REDIRECT_URI}`;
};

export const signOut = () => {
  window.history.replaceState({}, document.title, COGNITO_REDIRECT_URI);
};

// https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
export const getAuthToken = async (code) => {
  const endpoint = `https://${COGNITO_DOMAIN_PREFIX}.auth.${AWS_REGION}.amazoncognito.com/oauth2/token`;

  const authParams = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: COGNITO_CLIENT_ID,
    code: code,
    redirect_uri: COGNITO_REDIRECT_URI
  });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: authParams.toString()
  };

  const response = await fetch(endpoint, options);
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status} Message ${await response.text()}`);
  }

  return await response.json();
};
