import Clients from './Clients';

export const Home = () => {
  return (
    <div style={styles.container}>
      <Clients />
    </div>
  );
};

const styles = {
  container: {
    margin: '0 auto',
    padding: '50px 100px'
  }
};
