const getEnv = (name) => {
  /** avoid using process.env directly to circumvent Webpack replacement **/
  return process.env[name];
};

// With create react app, you need to prefix REACT_APP_ to the variable name. ex:
const BACKEND_API_URI= getEnv('REACT_APP_BACKEND_API_URI');
const COGNITO_CLIENT_ID = getEnv('REACT_APP_COGNITO_CLIENT_ID');
const COGNITO_DOMAIN_PREFIX = getEnv('REACT_APP_COGNITO_DOMAIN_PREFIX');
const COGNITO_REDIRECT_URI = getEnv('REACT_APP_COGNITO_REDIRECT_URI');
const AWS_REGION = getEnv('REACT_APP_AWS_REGION');

export {
  BACKEND_API_URI,
  COGNITO_CLIENT_ID,
  COGNITO_DOMAIN_PREFIX,
  COGNITO_REDIRECT_URI,
  AWS_REGION,
}
