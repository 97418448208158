import { BACKEND_API_URI } from '../constants/environment';

const fetchClients = async (idToken) => {
  const endpoint = `${BACKEND_API_URI}/clients`;
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  };

  const response = await fetch(endpoint, options);
  return await response.json();
};

const createClient = async (idToken, data) => {
  const access_scopes = [data.callsRead && 'calls/read', data.callsWrite && 'calls/write'].filter((item) => !!item);

  const endpoint = `${BACKEND_API_URI}/clients`;
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    body: JSON.stringify({ ...data, access_scopes })
  };

  const response = await fetch(endpoint, options);
  return await response.json();
};

const deleteClient = async (idToken, clientId) => {
  const endpoint = `${BACKEND_API_URI}/clients/${clientId}`;
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  };

  const response = await fetch(endpoint, options);
  return await response.blob();
};

export { fetchClients, createClient, deleteClient };
